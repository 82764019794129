.upperCase {
 text-transform: uppercase;  
 margin-bottom: 10; 
}

.verticalAlign{
    /* position: relative; */
    /* top: 50%; */
    /* transform: translateY(-50%);     */
    display:flex; align-items:center;
}