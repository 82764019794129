.dropzone{
    width: 150px;
    border-radius:52%; 
  }
  
  .overlay {
      position: absolute;
      border-radius:90%;
      top: 0;
      left: 5.5em;
      height: 20%;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .3s ease;
      background-color:#D3D3D3;
    }