.header{
    text-align: center;
}

.upload-icon {
    width: 50px;
    height: 50px;
    /* background: url() no-repeat center center;  */
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}